define(['app'], function(app) {

  const accountCreate = () => {
    const emailRegex = new RegExp('^[0-9a-zA-Z]+([0-9a-zA-Z]*[-._+])*[0-9a-zA-Z]+@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6}$');

    const component = {};
    component.config = {
      selectors: {
        customerEmail: 'customerEmail',
        emailAlert: '.email-in-use-options',
        resetPasswordBtn: 'email-in-use-reset',
        forgottenPasswordInput: 'forgot-password',
        hiddenForm: 'hidden-forgot-password-form',
        passwordResetFailure: '.account-create-reset-failure',
        passwordResetSuccess: '.account-create-reset-success',
        passwordResetInvalid: '.account-create-reset-invalid',
        csrfToken: 'csrf_token',
        spinner: '.account-create-spinner',
        registerButton: '.accountSignUp_submitButton',
        registerError: '.accountSignUp_alert-danger'
      },
      classList: {
        customerEmailInput: 'createAccount_customerEmailInput',
        stopSpinner: 'createAccount_stopSpinner',
        hideAlert: 'createAccount_hideAlert',
        showAlert: 'createAccount_showAlert'
      },
      elements: {
        spinner :'<img class="account-create-spinner" alt="spinner" src="/common/images/loading.gif"/>'
      }
    };

    component.init = () => {
      component.customerEmailInput = document.getElementById(component.config.selectors.customerEmail);
      component.customerEmailExistsAlert = document.querySelector(component.config.selectors.emailAlert);
      component.resetPasswordBtn = document.getElementById(component.config.selectors.resetPasswordBtn);
      component.forgottenPasswordInput = document.getElementById(component.config.selectors.forgottenPasswordInput);
      component.hiddenForm = document.getElementById(component.config.selectors.hiddenForm);
      component.passwordResetFailure = document.querySelector(component.config.selectors.passwordResetFailure);
      component.passwordResetSuccess = document.querySelector(component.config.selectors.passwordResetSuccess);
      component.passwordResetInvalid = document.querySelector(component.config.selectors.passwordResetInvalid);
      component.passwordResetArray = [component.passwordResetFailure, component.passwordResetSuccess, component.passwordResetInvalid];
      component.csrfToken = document.getElementsByName(component.config.selectors.csrfToken);
      component.registerButton = document.querySelector(component.config.selectors.registerButton);
      component.registerError = document.querySelectorAll(component.config.selectors.registerError);

      if (component.registerError){
        component.checkErrors();
      }

      if (component.customerEmailExistsAlert) {
        component.bindEventListeners();
        component.saveEmailAddress();
      }
    };

    component.bindEventListeners = () => {
      component.resetPasswordBtn.addEventListener('click', component.resetPassword);
      component.hiddenForm.addEventListener('submit', component.sendResetPasswordRequest);
    };

    component.saveEmailAddress = () => {
      if (component.customerEmailInput && component.forgottenPasswordInput) {
        component.updateLocalStorage(component.customerEmailInput.value);
        component.forgottenPasswordInput.value = component.customerEmailInput.value;
      }
    };

    component.updateLocalStorage = (value) => {
      localStorage.setItem('usernameEmail', value);
    };

    component.showEmailErrorMessage = (subject) => {
      if (component.customerEmailExistsAlert &&
          !component.customerEmailExistsAlert.classList.contains(component.config.classList.hideAlert)) {
        component.customerEmailExistsAlert.classList.add(component.config.classList.hideAlert);
      }

      const alertsToHide = component.passwordResetArray.filter(item => item !== subject);

      if (alertsToHide && alertsToHide.length > 0 ) {
        alertsToHide.map((item) => {
          if (item instanceof HTMLElement) {
            if (item.classList.contains(component.config.classList.showAlert)) {
              item.classList.remove(component.config.classList.showAlert);
            }
            item.classList.add(component.config.classList.hideAlert);
          }
        });
      }

      if (subject) {
        if (subject.classList.contains(component.config.classList.hideAlert)) {
          subject.classList.remove(component.config.classList.hideAlert);
        }
        subject.classList.add(component.config.classList.showAlert);
      }
    };

    component.encode = (value) => {
      return window.encodeURIComponent(value);
    };

    component.constructPostDataFromHiddenForm = () => {
      let postData = '';

      if (component.forgottenPasswordInput) {
        postData += `${component.encode(component.forgottenPasswordInput.name)}=${component.encode(component.forgottenPasswordInput.value)}&`;
      }

      if (component.csrfToken) {
        const csrfToken = component.csrfToken[0];
        postData += `${component.encode(csrfToken.name)}=${component.encode(csrfToken.value)}`;
      }

      return postData;
    };

    component.showSuccessMessage = () => {
      if (component.forgottenPasswordInput && component.passwordResetSuccess &&
          component.passwordResetSuccess.firstElementChild.tagName === 'P') {
        component.passwordResetSuccess.firstElementChild.innerHTML += ' ' + component.forgottenPasswordInput.value;
      }
    };

    component.showSpinner = () => {
      component.customerEmailExistsAlert.classList.add(component.config.classList.customerEmailInput);
      component.customerEmailExistsAlert.insertAdjacentHTML('beforeend', component.config.elements.spinner);
    };

    component.stopSpinner = () => {
      component.customerEmailExistsAlert.classList.add(component.config.classList.stopSpinner);
      component.spinner = document.querySelector(component.config.selectors.spinner);
      component.spinner.remove();
    };

    component.handleAjaxSuccess = () => {
      component.showEmailErrorMessage(component.passwordResetSuccess);
      component.showSuccessMessage();
      component.stopSpinner();
    };

    component.handleAjaxError = () => {
      component.showEmailErrorMessage(component.passwordResetFailure);
      component.stopSpinner();
    };


    component.sendResetPasswordRequest = (event) => {
      component.showSpinner();

      app.ajax.post({
        url: '/password.reset?action=send',
        method: 'POST',
        requestHeader: {
          header: 'content-type',
          value: 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        send: component.constructPostDataFromHiddenForm(),
        success: component.handleAjaxSuccess,
        error: component.handleAjaxError
      });
      event.preventDefault();
    };

    component.resetPassword = (event) => {
      event.preventDefault();

      if (component.forgottenPasswordInput.value && emailRegex.test(component.forgottenPasswordInput.value)) {
        if (component.customerEmailInput.value !== localStorage.getItem('usernameEmail').value) {
          component.forgottenPasswordInput.value = component.customerEmailInput.value;
          component.updateLocalStorage(component.customerEmailInput.value);
        }
        component.hiddenForm.dispatchEvent(new Event('submit'));
      } else {
        component.showEmailErrorMessage(component.passwordResetInvalid);
      }
    };

    component.checkErrors = () => {

      if (document.querySelector(component.config.selectors.registerError)) {

        const errors = document.querySelectorAll(component.config.selectors.registerError);
        const errorList = [];

        errors.forEach((error)=> {
          errorList.push(error.innerText);
        })

        window.dataLayer.push({
          'event': 'ElysiumEvent',
          'event_name':'login_failed',
          'error_value': errorList
        });
      }
    };

    return component;
  };

  return accountCreate;
});
